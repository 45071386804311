@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Regular.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Medium.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Medium.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
