.hig__text-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'ArtifaktElement', sans-serif;
  color: black;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  cursor: pointer; }

.hig__text-link--primary {
  color: #0696d7; }

.hig__text-link--primary:hover {
    color: #0671a1; }

.hig__text-link--primary:active, .hig__text-link--primary:focus {
    outline: none;
    color: #024b6c; }

.hig__text-link--secondary {
  color: rgba(0, 0, 0, 0.5); }

.hig__text-link--secondary:hover {
    color: #0696d7; }

.hig__text-link--secondary:active, .hig__text-link--secondary:focus {
    outline: none;
    color: #024b6c; }
